import React from 'react'
import Appetize from '../../components/Appetize/Appetize';

const UberClone = () => {
    return (
        <div>
            <Appetize title={ "Uber Clone" } iframe={ "https://appetize.io/embed/hejn72htgt1uap3u0dcaq2bam4?device=iphone8" } />
        </div >
    )
}

export default UberClone