//? Media Queries
export const mediaQueries = {
    mobile: 640,
    ipad: 768,
    laptop: 1025,
    desktop: 1280,
};

export const projects = [
    {
        title: 'Flappy Bird 100% React Native',
        description: "A re-creation of the game Flappy Bird created solely in React Native, no game engine. For this project I wanted to test out generating a PWA from a React Native App",
        image: 'flappy-bird.jpg',
        tags: [ 'React Native', '', 'React-Native-Web' ],
        source: '/flappy',
        visit: 'https://github.com/pizzacoffeecode/Native-FlappyBird',
        newWindow: false,
        id: 0,
    },
    {
        title: 'AB Academy',
        description: "A website for a local soccer coach, including a scheduling api and contact form. The clients website required many videos, so reducing load times down was critical. Good NextJs practices along with a serious amount of video and image optimization got the website a 100% perfomance score on lighthouse.",
        image: 'ab2.webp',
        tags: [ 'React', 'Node.js', 'NextJS' ],
        source: 'https://ab-academy.vercel.app',
        visit: 'https://github.com/pizzacoffeecode/ab-academy',
        newWindow: true,
        id: 0,
    },
    {
        title: 'Uber Clone',
        description: "A challenge to see if I could implement some the core functionality of the Uber app in a react native project with Redux handling the state. The project is a WIP featuring google location services, triangulation, routing, and cost calculation. The next step will be to add location on press and start the uber eats section",
        image: 'uber.webp',
        tags: [ 'React Native', 'Redux', 'Google Location' ],
        source: '/uberclone',
        visit: 'https://github.com/pizzacoffeecode/uber_clone',
        newWindow: false,
        id: 2,
    },
    {
        title: 'Sports Betting Blog',
        description: "A sports betting blog utilizing Graph CMS as a user accessible backend. Both the front and backend were built in NextJs",
        image: 'blog.webp',
        tags: [ 'Next Js', 'Graph CMS', 'GraphQL' ],
        source: 'https://graphcms-blog-blush.vercel.app',
        visit: 'https://github.com/pizzacoffeecode/graphcms_blog',
        newWindow: true,
        id: 1,
    },
    {
        title: 'Search & Pathing Algorithms',
        description: "As part of my ongoing study of data structures and algorithms I decided to tackle some of the most popular search and pathing algorithms.  Unity Engine has been used to create a graphical representation of the various algorithms calculate the same path with obstacles and varying tile costs.",
        image: 'astar.webp',
        tags: [ 'Unity', '', 'C#' ],
        source: '/astar',
        visit: 'https://github.com/pizzacoffeecode/',
        newWindow: false,
        id: 3,
    },
    {
        title: 'Observer Pattern in C# with Unity GUI',
        description: "A pure C# implementation of the Observer Pattern with a Unity overlay to demonstrate the functionality",
        image: 'observer.png',
        tags: [ 'Unity', '', 'C#' ],
        source: '/observer',
        visit: 'https://github.com/pizzacoffeecode/',
        newWindow: false,
        id: 3,
    },
];