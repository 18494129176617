import styled from 'styled-components';


export const HomeWrapper = styled.div`
  width: calc(100vw);
  /* max-width: 1280px; */
  /* height: calc(100vh - 60px); */
  margin-top: 100px;
  min-height: 2000px;
  /* padding: 2rem 48px 40px;
  margin: 1rem auto; */
  box-sizing: content-box;
  align-items: center;
  justify-content: center;



  margin: 0 auto;


  @media ${ props => props.theme.breakpoints.sm } {
    width: calc(100% - 16px);
  }
`




// max-width: 1280px;
// width: 100%;
// margin: auto;
// `;
