import React from 'react'
import './About.css'

const About = () => {

    return (
        <div className='about__title'>About</div>
    )
}

export default About