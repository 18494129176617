import React from 'react'
import Appetize from '../../components/Appetize/Appetize';

const Flappy = () => {
    return (
        <div>
            <Appetize title={ "Flappy React" } iframe={ "https://appetize.io/embed/bkvrbvowwi4t3fvovtfvushbdu?device=pixel4&osVersion=11.0&scale=75" } />
        </div >
    )
}

export default Flappy