import styled from 'styled-components'

export const Section = styled.section`
display: ${ (props) => props.grid ? "grid" : "flex" };
flex-direction: ${ (props) => props.row ? "row" : "column" };

align-items: ${ (props) => props.alignCenter ? "center" : "unset" };
grid-auto-flow : ${ (props) => props.gridrow ? "row" : "column" };

grid-template-columns: ${ (props) => props.cols };

padding: ${ (props) => props.nopadding ? "0" : "50px 48px 0" } ;

margin: auto;
max-width: 1040px;

box-sizing: content-box;
position: relative;
margin-top: 20px;
justify-content: center;

z-index: 2;

@media ${ (props) => props.theme.breakpoints.sm } {
  flex-direction: column;
}
@media ${ (props) => props.theme.breakpoints.md } {
  padding: 24px 48px 0; 
}

@media ${ (props) => props.theme.breakpoints.lg } {
  padding: ${ (props) => props.nopadding ? "0" : "16px 16px 0" } ;
  width: calc(100% - 32px);

}
`





export const SectionTitle = styled.h2`

font-weight: 800;
font-size: ${ (props) => props.main ? '65px' : '56px' };
/* line-height: ${ (props) => props.main ? '72px' : '56px' }; */
width: max-content;
max-width: 100%;
background: linear-gradient(121.57deg, #FFFFFF 18.77%, rgba(255, 255, 255, 0.66) 60.15%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

margin-bottom: 16px;
/* padding: ${ (props) => props.main ? '58px 0 16px' : '0' }; */

@media ${ props => props.theme.breakpoints.md }{
  font-size: ${ (props) => props.main ? '56px' : '48px' };
  /* line-height: ${ (props) => props.main ? '56px' : '48px' };
  margin-bottom: 12px;
  padding: ${ (props) => props.main ? '40px 0 12px' : '0' }; */
}

@media ${ props => props.theme.breakpoints.sm }{
  font-size: 40px;
  /* line-height: 5px; */
  /* font-size: ${ (props) => props.main ? '28px' : '32px' }; */
  /* line-height: ${ (props) => props.main ? '32px' : '40px' }; */
  /* margin-top: -40px; */
  margin-bottom: 10px;
  margin-left: 32px;
  margin-right: 32px;

  /* padding: ${ (props) => props.main ? '16px 0 8px' : '0' }; */
  max-width: 100%;
}
`

export const SectionText = styled.p`
max-width: 800px;
font-size: 24px;
line-height: 40px;
font-weight: 300;
padding-bottom: 3.6rem;
color: #FFFFFF;

@media ${ (props) => props.theme.breakpoints.md } {
  max-width: 670px;
  font-size: 20px;
  line-height: 32px;
  padding-bottom: 24px;
}

@media ${ (props) => props.theme.breakpoints.sm } {
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 20px;

  margin-left: 32px;
  margin-right: 32px;
  margin: 40px 32px 32px 32px;
}
`

export const SectionTextFull = styled.p`
text-align: justify;
max-width: 100%;
font-size: 24px;
line-height: 40px;
font-weight: 300;
padding: 1rem 3.9rem 1rem 3.5rem;
place-items: center;
color: #FFFFFF;

@media ${ (props) => props.theme.breakpoints.md } {
  max-width: 670px;
  font-size: 20px;
  line-height: 32px;
  padding-bottom: 20px;

  margin-left: 32px;
  margin-right: 32px;
}

@media ${ (props) => props.theme.breakpoints.sm } {
  font-size: 16px;
  line-height: 24px;
  padding: unset;
  padding-bottom: 20px;

  margin-left: 32px;
  margin-right: 32px;
}
`

export const SectionDivider = styled.div`

width: 64px;
height: 6px;
border-radius: 10px;
background-color: #fff;
background: ${ (props) => props.colorAlt ?
    'linear-gradient(270deg, #F46737 0%, #945DD6 100%)' :
    'linear-gradient(45deg, rgba(0, 194, 255, 1), rgba(250, 0, 255, 1))' };
  margin-bottom: ${ (props) => props.divider ? "8rem" : "2rem" };

@media ${ (props) => props.theme.breakpoints.md } {
  width: 48px;
  height: 4px;
}

@media ${ (props) => props.theme.breakpoints.sm } {
  width: 32px;
  height: 2px;
}
`
export const SectionSubText = styled.p`
max-width: 800px;
font-weight: 300;
font-size: 18px;
line-height: 32px;
color: rgba(255, 255, 255, 0.75);

@media ${ (props) => props.theme.breakpoints.md } {
  max-width: 672px;
  font-size: 16px;
  line-height: 25px;
}

@media ${ (props) => props.theme.breakpoints.sm } {
  font-size: 14px;
  line-height: 22px;
}
`
export const SecondaryBtn = styled.button`
color: #FFF;
background: none;
border: 1px solid rgba(255, 255, 255, 0.33);
box-sizing: border-box;
border-radius: 999px;
padding: 16px 24px;
font-weight: 600;
font-size: 18px;
line-height: 16px;
width: fit-content;
margin-top: 32px;
margin-bottom: 80px;
cursor: pointer;
transition: 0.4s ease;
&:focus {
  outline: none;
}

&:hover {
  color: #0f1624;
  background: #fff;
  border: 1px solid #fff;
}

&:active {
  background: #e0e4eb;
  border: 1px solid #304169;
  box-shadow: inset 0px 2px 1px rgba(46, 49, 55, 0.15), inset 0px 0px 4px rgba(20, 20, 55, 0.3);
}

@media ${ (props) => props.theme.breakpoints.md }{
  margin-top: 24px; 
  margin-bottom: 64px;
  padding: 16px 24px;
  width: fit-content;
  font-size: 20px;
  line-height: 20px;
}

@media ${ (props) => props.theme.breakpoints.sm } {
  margin-top: 16px;
  margin-bottom: 40px;
  padding: 8px 16px;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
}
`

export const ButtonBack = styled.div`
width: ${ ({ alt }) => alt ? '150px' : '262px' };
height: ${ ({ alt }) => alt ? '52px' : '64px' };
border-radius: 50px;

/* font-family: var(--font-button); */
font-family: "Aquire";
font-size: ${ ({ alt }) => alt ? '20px' : '24px' };
font-weight: 600;
display: flex;
align-items: center;
justify-content: center;
margin: ${ ({ alt, form }) => (alt || form) ? '0' : '0 0 80px' };
color: #fff;
background: ${ ({ alt }) => alt ? 'linear-gradient(270deg, #ff622e 0%, #B133FF 100%)' : 'linear-gradient(45deg, rgba(0, 194, 255, 1), rgba(250, 0, 255, 1))' };
cursor: pointer;
transition: 0.5s ease;
position: relative;
overflow: hidden;

opacity: ${ ({ disabled }) => disabled ? '.5' : '1' };

@media ${ (props) => props.theme.breakpoints.md } {
  width: ${ ({ alt }) => alt ? '150px' : '184px' };
  height: ${ ({ alt }) => alt ? '52px' : '48px' };
  font-size: ${ ({ alt }) => alt ? '20px' : '16px' };
  margin-bottom: ${ ({ alt }) => alt ? '0' : '64px' };
}

@media ${ (props) => props.theme.breakpoints.sm } {
  width: 80%;
  height: 32px;
  font-size: 14px;
  margin-bottom: ${ ({ alt }) => alt ? '0' : '32px' };
  margin-left: 32px;
  margin-right: 32px;
}
`

export const ButtonFront = styled.button`
border: none;
border-radius: 50px;
color: #fff;
display: block;

/* display: flex; */
position: absolute;
top: 0;
left: 0;
width: calc(100% - 4px);
height: calc(100% - 4px);

margin: 2px 0 0 2px;

background: #000000;
/* background: ${ ({ alt }) => alt ? 'linear-gradient(270deg, #F46737 0%, #945DD6 100%)' : 'linear-gradient(45deg, rgba(0, 194, 255, 1), rgba(250, 0, 255, 1))' }; */
opacity: ${ ({ disabled }) => disabled ? '.5' : '1' };
transition: .4s ease;

/* font-family: var(--font-button); */
font-family: "Aquire";
font-size: ${ ({ alt }) => alt ? '20px' : '24px' };
font-weight: 600;
align-items: center;
justify-content: center;
cursor: pointer;
box-shadow: ${ ({ disabled }) => disabled ? 'inset 0px 2px 1px rgba(46, 49, 55, 0.15), inset 0px 0px 4px rgba(20, 20, 55, 0.3)' : 'none' };

&:hover {
  opacity: 0;
}
&:focus {
  outline: none;
}
&:active {
  opacity: 1;
  box-shadow: inset 0px 2px 1px rgba(46, 49, 55, 0.15), inset 0px 0px 4px rgba(20, 20, 55, 0.3);
}

&:disabled{
  background: linear-gradient(270deg, #00DBD8 0%, #ff275f 100%);
  opacity: 0.5;
  box-shadow: inset 0px 2px 1px rgba(46, 49, 55, 0.15), inset 0px 0px 4px rgba(20, 20, 55, 0.3);
}

@media ${ (props) => props.theme.breakpoints.md } {
  font-size: ${ ({ alt }) => alt ? '20px' : '16px' };
}

@media ${ (props) => props.theme.breakpoints.sm } {
  font-size: 14px;
}
`

export const LinkContainer = styled.div`
margin-left: ${ ({ large }) => large ? '24px' : '16px' };
transition: 0.3s ease;
justify-content: center;
border-radius: 50px;
padding: 8px;

&:hover {
  background-color: #212d45;
  transform: scale(1.2);
  cursor: pointer;
}

@media ${ (props) => props.theme.breakpoints.md } {
  margin-left: ${ ({ large }) => large ? '16px' : '8px' };

}
@media ${ (props) => props.theme.breakpoints.sm } {
  margin-left: ${ ({ large }) => large ? '0' : '8px' };
}
`